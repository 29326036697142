import React, { Props, ReactNode } from 'react';
import { graphql, Link } from 'gatsby';
import BaseComponent from '../../base/base-component';
import GravityForm from '../../components/gravity-forms/gravity-forms';
import WordPressPageLayout from '../../components/wordpress-page-layout/wordpress-page-layout';
import ContainerBox from '../../components/container-box/container-box';
import BreadCrumb from '../../components/bread-crumb/bread-crumb';
import SvgIcon from '../../components/svg-icon/svg-icon';
import { SvgIconPathCommand } from '../../components/svg-icon/svg-icon-enum';

export default class Contact extends BaseComponent<any> {
  public render(): ReactNode {
    const { [0]: form } = this.props.data.form.nodes;
    const { [0]: contactUsPage } = this.props.data.contactUsPage.nodes;
    return (
      <WordPressPageLayout title={contactUsPage.title}>
        <ContainerBox className="c-section--pageheader is-contact">
          <div className="row c-contact">
            <div className="col-lg-6 c-contact__headline">
              <div className="c-section">
                <h1 className="c-section__title">{contactUsPage.title}</h1>
                <div
                  className="s-text"
                  dangerouslySetInnerHTML={{ __html: contactUsPage.content }}
                ></div>
              </div>
            </div>
            <div className="col-lg-6 c-contact-form">
              <GravityForm form={form} formClassName="c-form-contact" />
            </div>
          </div>
        </ContainerBox>
        <ContainerBox className="c-section--press">
          <div className="row c-press">
            <div className="col-lg-6">
              <div className="c-press__wrapper">
                <h4 className="c-press__title">
                  Reach the full State of Democracy Project Team
                </h4>
                <div className="c-press__wrap">
                  <div className="c-press-icon">
                    <SvgIcon
                      width="60"
                      viewBox="0 0 60 60"
                      rect={[
                        {
                          rx: '30',
                          width: '60',
                          height: '60',
                          fill: '#4A1D96',
                        },
                      ]}
                      paths={[
                        {
                          command: SvgIconPathCommand.PRESS_ICOM,
                          fill: '#fff',
                        },
                      ]}
                    />
                  </div>
                  <div className="c-press-info">
                    <div className="c-press__text">
                      {contactUsPage.acfContactUs.caption}
                    </div>
                    <div className="c-press__text">
                      <a href={`mailto:${contactUsPage.acfContactUs.email}`}>
                        {contactUsPage.acfContactUs.email}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ContainerBox>
      </WordPressPageLayout>
    );
  }
}

export const query = graphql`
  query {
    form: allGfForm(filter: { slug: { eq: "contact" } }) {
      nodes {
        apiURL
        formFields {
          isRequired
          label
          type
          placeholder
          id
          cssClass
        }
        title
        button {
          text
        }
      }
    }
    contactUsPage: allWpPage(filter: { slug: { eq: "contact-us" } }) {
      nodes {
        id
        uri
        title
        status
        slug
        content
        link
        acfContactUs {
          caption
          email
          fieldGroupName
        }
      }
    }
  }
`;
